import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import api from '../../../../../../shared/api/adminUI.api';
import { useAuthContext } from '../../../../../auth/auth-context';
import { usePartnerCodesParams } from '../../../hooks/use-partner-codes-params';
import styles from './create-partner-codes.module.scss';
import {
  composeValidators,
  required,
  v,
} from '../../../../../../utils/validate.utils';
import { HelperText } from '../../../../../helper-text/helper-text';
import { AppError } from '../../../../../../shared/api/app-error';
import { downloadBlobFile } from '../../../../../../utils/download';

type FormValues = {
  codesCount: number;
};

type Props = {
  show: boolean;
  onHide: () => void;
};

export const CreatePartnerCodes = ({ show, onHide }: Props) => {
  const { getBearerToken } = useAuthContext();
  const { partnerId } = usePartnerCodesParams();
  const client = useQueryClient();

  const { mutateAsync: getCSV, isLoading } = useMutation({
    mutationFn: (id: number) => api.partners.codes.getCSV(id, getBearerToken),
  });

  const {
    mutateAsync: createCode,
    error,
    reset,
  } = useMutation({
    mutationFn: (codesCount: number) => api.partners.codes.create({ partnerId, codesCount }, getBearerToken),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const code = await createCode(+values.codesCount);
      client.invalidateQueries(['partner', partnerId, 'codes']);
      toast.success('Codes created successfully');
      onHide();

      const file = await getCSV(code.id);
      downloadBlobFile(file, `codes-${code.id}.csv`);
    } catch (err) {
      console.error('Error creating partner codes', err);
    }
  };

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  const appError = error ? AppError.fromError(error) : null;

  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header className={styles.header}>
        <Modal.Title>Generate Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form<FormValues>
          onSubmit={onSubmit}
          destroyOnUnregister
          render={({
            handleSubmit,
            submitting,
            errors,
            submitFailed,
            submitSucceeded,
            invalid,
          }) => {
            const hasError = !!errors.codesCount
              && invalid
              && (submitFailed || submitSucceeded);

            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.row}>
                  <span className={styles.text}>Generate</span>
                  <Field
                    className={styles.input}
                    component="input"
                    validate={composeValidators(
                      required,
                      v.num.min(1),
                      v.num.fraction(0, 'must be a whole number'),
                    )}
                    name="codesCount"
                    type="number"
                  />
                  <span className={styles.text}>enrollment codes</span>
                </div>
                {hasError ? (
                  <HelperText variant="error">{errors.codesCount}</HelperText>
                ) : null}
                {appError ? (
                  <HelperText variant="error">{appError.message}</HelperText>
                ) : null}
                <Modal.Footer className={styles.footer}>
                  <Button variant="secondary" onClick={onHide}>
                    Cancel
                  </Button>
                  <Button variant="success" type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
