import { Form, FormProps } from 'react-final-form';
import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './partner-form.module.scss';
import { PartnerFormField } from './partner-form-field/partner-form-field';
import {
  commentRequired,
  composeValidators,
  minLength,
  validateEmail,
} from '../../../../utils/validate.utils';
import { AppError } from '../../../../shared/api/app-error';
import { HelperText } from '../../../helper-text/helper-text';

const MESSAGE_MAP: Record<string | number, string> = {
  409: 'A partner account with this name already exists',
};

export type PartnerFormValues = {
  'partner-name': string;
  'partner-email': string;
  'partner-contact-name': string;
};

export type PartnerFormProps = {
  onCancel: () => void;
  error?: AppError | null;
} & Omit<FormProps<PartnerFormValues>, 'render'>;

export const PartnerForm = ({
  onCancel,
  error = null,
  ...props
}: PartnerFormProps) => {
  const getErrorMessage = () => {
    const appError = error ? AppError.fromError(error) : null;

    if (!appError) {
      return null;
    }

    const statusMessage = appError.data?.status
      ? MESSAGE_MAP[appError.data.status]
      : null;

    return statusMessage || appError.message;
  };

  const errorMessage = getErrorMessage();

  return (
    <Form<PartnerFormValues>
      {...props}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.container}>
            <PartnerFormField
              name="partner-name"
              label="Account Name"
              required
              validate={composeValidators(
                minLength(3),
                commentRequired('Please provide an account name'),
              )}
            />
            <PartnerFormField
              name="partner-email"
              label="Email"
              required
              validate={composeValidators(
                minLength(3),
                commentRequired('Please provide a partner email'),
                validateEmail,
              )}
            />
            <PartnerFormField
              name="partner-contact-name"
              label="Contact Name"
              placeholder="First Name Last Name, e.g. John Doe"
            />
          </div>
          {errorMessage ? (
            <HelperText variant="error">{errorMessage}</HelperText>
          ) : null}
          <div className={styles.footer}>
            <Button
              variant="secondary"
              onClick={onCancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit" disabled={submitting}>
              Save
            </Button>
          </div>
        </form>
      )}
    />
  );
};
