import moment from 'moment';
import { AnyObject } from 'final-form';
import * as EmailValidator from 'email-validator';
import { ISelectOption } from '../models/SelectOptions';

const checkIfTodaysDayIsInRange = (
  startDate: string,
  disableStartDateAfterXDays: number,
) => {
  const eighthDaysAfterStartDate = moment(startDate).add(
    disableStartDateAfterXDays,
    'days',
  );
  return moment().isBetween(startDate, eighthDaysAfterStartDate);
};

export const composeValidators = (...validators: any[]) => (value: any, allValues: any) => validators.reduce(
  (error: string, validator: any) => error || validator(value, allValues),
  undefined,
);

export const minLength = (min: number) => (value: string) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
export const required = (value: any) => (!value ? 'Required' : undefined);
export const requiredIf = (condition: boolean) => (value: string) => (condition && !value ? 'Required' : undefined);
export const validateEmail = (value: string) => (EmailValidator.validate(value)
  ? undefined
  : 'Please provide a valid email address.');
export const validateDate = (value: any) => {
  if (!value) {
    return undefined;
  }

  const date = moment(value);
  return date.isValid() ? undefined : 'Please provide a valid date';
};

export const validateDateRange = (
  startDate: string | Date | number,
  endDate: string | Date | number,
) => {
  if (!startDate || !endDate) {
    return undefined;
  }

  const start = moment(startDate);
  const end = moment(endDate);
  return start.isBefore(end) ? undefined : 'End date must be after start date';
};

export const commentRequired = (errorMessage: string) => (value: string) => (!value ? errorMessage : undefined);

export const requiredSubStatus = (option: ISelectOption, allValues: any) => {
  const requiredSubStatuses = ['dismissed', 'onLeaveOfAbsense', 'withdrawn'];
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return option.value === '-'
    && requiredSubStatuses.includes(programStatusValue)
    ? 'Please select sub status'
    : undefined;
};

export const requiredReason = (option: ISelectOption, allValues: any) => {
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return option.value === '-' && programStatusValue !== 'enrolled'
    ? 'Please select reason'
    : undefined;
};

export const commentRequiredProgramStatus = (value: string, allValues: any) => {
  const { programStatus } = allValues;
  const programStatusValue = programStatus.value;
  return !value && programStatusValue !== 'enrolled'
    ? 'Please provide comment for program status change'
    : undefined;
};

export const commentRequiredStartDate = (
  daysBeforeNextPeriod: number,
  disableStartDateAfterXDays: number,
  startDate: null | string,
): ((value: string, allValues: AnyObject) => string | undefined
  ) => (value: string): string | undefined => {
  const now = moment();
  if (startDate) {
    const startDateFormated = moment(moment(startDate).format('DD MMM YYYY'));
    const daysBeforeStart = moment
      .duration(startDateFormated.diff(now))
      .asDays();
    const isLaterThenXDaysBeforeStart = daysBeforeStart <= daysBeforeNextPeriod;
    const isBetweeenStartDateAndEigthDaysAfter = checkIfTodaysDayIsInRange(
      startDate,
      disableStartDateAfterXDays,
    );
    return (isLaterThenXDaysBeforeStart
        || isBetweeenStartDateAndEigthDaysAfter)
        && !value
      ? 'Comment is required'
      : undefined;
  }
  return undefined;
};

export const selectRequired = (option: ISelectOption) => (option.value === '-' ? 'Option is empty' : undefined);

export const isEmptyValue = (option: ISelectOption) => (option.value === '-' ? null : option.value);

export const dataSelectorRequired = (value: string) => (value === null ? 'Please select date' : undefined);

// TODO - use a library for this
const num = {
  min: (min: number) => (value: any) => {
    const val = +value;
    if (Number.isNaN(val)) {
      return 'Must be a number';
    }

    return val < min ? `Must be at least ${min}` : undefined;
  },
  max: (max: number) => (value: any) => {
    const val = +value;
    if (Number.isNaN(val)) {
      return 'Must be a number';
    }

    return val > max ? `Must be at most ${max}` : undefined;
  },
  fraction: (digits: number, message?: string) => (value: any) => {
    const val = +value;
    if (Number.isNaN(val)) {
      return 'Must be a number';
    }

    if (val * 10 ** digits - Math.trunc(val * 10 ** digits) < Number.EPSILON) return undefined;

    return message || `Must have at most ${digits} decimal places`;
  },
};

export const v = {
  num,
};
