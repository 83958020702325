import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import styles from './partner-codes-header.module.scss';
import { usePartner } from '../../../hooks/use-partner';
import { usePartnerCodesParams } from '../../hooks/use-partner-codes-params';
import { CreatePartnerCodes } from './create-partner-codes/create-partner-codes';

export const PartnerCodesHeader = () => {
  const { partnerId } = usePartnerCodesParams();
  const { data } = usePartner(partnerId, { suspense: true });

  const [show, setShow] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h4>Code Batch History: {data?.accountName}</h4>
      </div>
      <Button variant="success" onClick={() => setShow(true)}>
        <FaPlus /> Generate Codes
      </Button>
      <CreatePartnerCodes show={show} onHide={() => setShow(false)} />
    </div>
  );
};
